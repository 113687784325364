import * as Sentry from "@sentry/vue";
import chalk from "chalk";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const config = useRuntimeConfig();
    const { nodeEnv = "development" } = config.public;

    console.log(chalk.green("Sentry is enabled on environment: ", nodeEnv));
    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC as string,
      environment: nodeEnv,
      integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.globalHandlersIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.contextLinesIntegration(),
        Sentry.linkedErrorsIntegration(),
      ],
      defaultIntegrations: false,
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
      replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,
      replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
      profilesSampleRate: config.public.SENTRY_PROFILING_SAMPLE_RATE as number
    });
  }
});
