import { reactive } from "vue";

const authStatus = reactive({
	status: "unauthenticated",
	data: null
});

const getAuthStatus = () => {
	return authStatus;
};

let runtimeConfig = null;


const refreshAuthStatus = async () => {
	return new Promise(async (resolve, reject) => {
		const token = useCookie("token");
		if (!runtimeConfig) runtimeConfig = useRuntimeConfig();
		if (!token || !token?.value) {
			authStatus.status = "unauthenticated";
			authStatus.data = null;
			return resolve(null);
		} else {
			//const runtimeConfig = useRuntimeConfig();
			$fetch(`/users/@me`, {
				method: "GET",
				baseURL: runtimeConfig.public.apiBase,
				headers: {
					Authorization: `Bearer ${ token.value }`
				}
			}).then(async (data) => {
				if (data?.success && data?.user) {
					authStatus.status = "authenticated";
					authStatus.data = data;
					resolve(data);
				} else {
					authStatus.status = "unauthenticated";
					authStatus.data = null;
					resolve(null);
				}
			}).catch((e) => {
				const { code } = e.response._data;
				if (code === "SESSION_EXPIRED") {
					authStatus.status = "unauthenticated";
					authStatus.data = null;
				} else {
					console.error(e);
					console.error(e.response._data);
				}
				resolve(null);
			});
		}
	});
};

export const useAuth = (opts = {}) => {
	const { status, data } = authStatus;
	const getters = {
		status,
		data
	};
	const actions = {
		getAuthStatus,
		refreshAuthStatus
	};
	return {
		...getters,
		...actions
	};
};
export default useAuth;
