import revive_payload_client_P4DCe094kj from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BT67APvr3P from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wRTjfgPoPm from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VKCkvNMaJ3 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_87O8Irl5es from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_rSGao7pm1h from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import i18n_lpcr5Qx5bt from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.26/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_OXvkGYXV33 from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_focus-trap@7.5.4_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocs_r3cey3x6tq2ozk3cgmwxtxyh5y/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_pacJQtT6FQ from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_focus-trap@7.5.4_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocs_r3cey3x6tq2ozk3cgmwxtxyh5y/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_bAAdEmBcLg from "/app/node_modules/.pnpm/@nuxt+ui@2.15.2_focus-trap@7.5.4_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocs_r3cey3x6tq2ozk3cgmwxtxyh5y/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_0es7dvT31Z from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_zoF4yPIBkC from "/app/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import chunk_reload_client_vLPM8GCi77 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_encoding@0.1.13_fl_47767ua3ms7zphlvrskdp6hzpa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import google_recaptcha_rYK9Kziv68 from "/app/plugins/google-recaptcha.ts";
import mitt_ZNXaeqsgM5 from "/app/plugins/mitt.js";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import sockets_tf4I3c66A4 from "/app/plugins/sockets.js";
import vuesax_IhK6CckThS from "/app/plugins/vuesax.js";
export default [
  revive_payload_client_P4DCe094kj,
  unhead_BT67APvr3P,
  router_wRTjfgPoPm,
  payload_client_VKCkvNMaJ3,
  check_outdated_build_client_87O8Irl5es,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rSGao7pm1h,
  plugin_6Ph1iQzNHk,
  i18n_lpcr5Qx5bt,
  slideovers_OXvkGYXV33,
  modals_pacJQtT6FQ,
  colors_bAAdEmBcLg,
  plugin_client_0es7dvT31Z,
  plugin_zoF4yPIBkC,
  chunk_reload_client_vLPM8GCi77,
  google_recaptcha_rYK9Kziv68,
  mitt_ZNXaeqsgM5,
  sentry_3AyO8nEfhE,
  sockets_tf4I3c66A4,
  vuesax_IhK6CckThS
]