import {VueReCaptcha} from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
    const runtimeConfig = nuxtApp.$config;
    nuxtApp.vueApp.use(VueReCaptcha, {
        siteKey: runtimeConfig.public.recaptchaSiteKey,
        loaderOptions: {
            autoHideBadge: true,
        },
    });
});
