let socket;
export default defineNuxtPlugin(() => {
	return {
		provide: {
			sockets: {
				set: (value) => {
					socket = value;
				},
				on: (event, callback) => {
					socket.on(event, callback);
				},
				emit: (event, data) => {
					socket.emit(event, {
						...data
					});
				},
				off: (event, callback) => {
					socket.off(event, callback);
				},
				get: () => {
					return socket;
				}
			}
		}
	};
});

/*
const sockets = {};
export default defineNuxtPlugin(() => {
	return {
		provide: {
			sockets: {
				set: (value, channel) => {
					sockets[channel] = value;
				},
				on: (event, callback, channel) => {
					sockets[channel].on(event, callback);
				},
				emit: (event, data, channel) => {
					sockets[channel].emit(event, {
						...data
					});
				},
				off: (event, callback, channel) => {
					sockets[channel].off(event, callback);
				},
				get: (channel) => {
					return sockets[channel];
				}
			}
		}
	};
});
 */