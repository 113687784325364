<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
  <Modal ref="modal"></Modal>
</template>

<script setup>
import chalk from "chalk";

const config = useRuntimeConfig();
useHead({
  title: `${ config.public.appName } - Dashboard`
});

// Get dev mod
const env = config.public.nodeEnv;
if (env === "production") {
  console.log(chalk.green("HubSpot tracking is enabled"));
  useHead({
    script: [{
      src: "//js-eu1.hs-scripts.com/144946209.js",
      body: true,
      defer: true,
      id: "hs-script-loader",
      async: true
    }]
  });
} else {
  console.log(chalk.yellow("HubSpot tracking is disabled in development mode"), env);
}
window.hsConversationsSettings = {
  loadImmediately: false
};

useHead({
  script: [{
    src: "/libraries/konva.js",
    body: true
  }, {
    src: "/libraries/tinycolor.js",
    body: true
  }, {
    src: "/libraries/canvas.js",
    body: true
  }, {
    src: "/libraries/events.js",
    body: true
  }, {
    src: "/libraries/webfont.js",
    body: true
  }],
  meta: [{
    name: "viewport",
    content: "width=device-width, initial-scale=1"
  }, {
    name: "viewport",
    content: "height=device-height, initial-scale=1"
  }]
});
</script>

<script>
import { fetch } from "~/utils/helper";
import { setUser } from "@sentry/vue";

window.hsConversationsSettings = {
  loadImmediately: false
};

export default {
  name: "Index",
  beforeMount() {
    const nuxtApp = useNuxtApp();
    const { $sockets } = nuxtApp;
    $sockets.set(this.$nuxtSocket({
      name: "main",
      closeOnBeforeunload: false,
      withCredentials: true
    }));
    $sockets.on("disconnect", () => {
      console.log(`[SOCKET] Disconnected from ${ $sockets.get().io.uri }`);
      // try to reconnect
      if ($sockets.connectionTimeout) {
        clearTimeout($sockets.connectionTimeout);
      }
      $sockets.connectionTimeout = setTimeout(() => {
        console.log(`[SOCKET] Trying to reconnect to ${ $sockets.get().io.uri }`);
        if (!$sockets.initiated) $sockets.get().connect();
        else clearTimeout($sockets.connectionTimeout);
      }, 100);
    });
    $sockets.on("connected", () => {
      console.log(`[SOCKET] Soft connected to ${ $sockets.get().io.uri } from ${ this.$route.path + "/" }`);
    });
  },
  mounted() {
    const nuxtApp = useNuxtApp();
    if (!nuxtApp.$swal) nuxtApp.provide("swal", this.$refs.modal);
    const config = useRuntimeConfig();
    const env = config.public.nodeEnv;
    if (env === "production" && nuxtApp.$user) {
      fetch("/trakingToken").then((res) => {
        const { email, token } = res;
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: token
        };
        window.HubSpotConversations.widget.load();
        console.log(chalk.green("HubSpot widget is enabled"));
      });
    }
    if (nuxtApp.$user) {
      setUser({
        email: nuxtApp.$user.email,
        fullname: nuxtApp.$user.displayname,
        id: nuxtApp.$user.id
      });
    }
  }
};

</script>
