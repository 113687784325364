import { default as accounts7sxrnc6xkZMeta } from "/app/pages/admin/accounts.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as ordersqtK0Nf5Us2Meta } from "/app/pages/admin/orders.vue?macro=true";
import { default as transactionsxBQPUJh4s4Meta } from "/app/pages/admin/transactions.vue?macro=true";
import { default as indexFXjYqPb3WZMeta } from "/app/pages/customers/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_937Kbaa5vEkyMeta } from "/app/pages/invite/[id].vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as filesicbWYSgJyxMeta } from "/app/pages/orders/[id]/files.vue?macro=true";
import { default as createnzDtnXXm8IMeta } from "/app/pages/orders/create.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_93vZXVq7IOTKMeta } from "/app/pages/password-reset/[id].vue?macro=true";
import { default as indexYgRHYxGzjsMeta } from "/app/pages/password-reset/index.vue?macro=true";
import { default as index4DIviTw3fSMeta } from "/app/pages/payments/index.vue?macro=true";
import { default as _91id_938d7mq4Zm2kMeta } from "/app/pages/register/[id].vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as accountFbmhd9M064Meta } from "/app/pages/settings/account.vue?macro=true";
import { default as userCkZPhnbvCdMeta } from "/app/pages/settings/user.vue?macro=true";
import { default as indexzqmHNbk6IKMeta } from "/app/pages/stocks/index.vue?macro=true";
import { default as indexH6dMEPckhVMeta } from "/app/pages/team/index.vue?macro=true";
import { default as _91id_930r1TxNLWFNMeta } from "/app/pages/team/users/[id].vue?macro=true";
export default [
  {
    name: accounts7sxrnc6xkZMeta?.name ?? "admin-accounts___en",
    path: accounts7sxrnc6xkZMeta?.path ?? "/en/admin/accounts",
    meta: accounts7sxrnc6xkZMeta || {},
    alias: accounts7sxrnc6xkZMeta?.alias || [],
    redirect: accounts7sxrnc6xkZMeta?.redirect,
    component: () => import("/app/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    name: accounts7sxrnc6xkZMeta?.name ?? "admin-accounts___fr",
    path: accounts7sxrnc6xkZMeta?.path ?? "/admin/accounts",
    meta: accounts7sxrnc6xkZMeta || {},
    alias: accounts7sxrnc6xkZMeta?.alias || [],
    redirect: accounts7sxrnc6xkZMeta?.redirect,
    component: () => import("/app/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin___en",
    path: indexi8nCATZISJMeta?.path ?? "/en/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin___fr",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: ordersqtK0Nf5Us2Meta?.name ?? "admin-orders___en",
    path: ordersqtK0Nf5Us2Meta?.path ?? "/en/admin/orders",
    meta: ordersqtK0Nf5Us2Meta || {},
    alias: ordersqtK0Nf5Us2Meta?.alias || [],
    redirect: ordersqtK0Nf5Us2Meta?.redirect,
    component: () => import("/app/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersqtK0Nf5Us2Meta?.name ?? "admin-orders___fr",
    path: ordersqtK0Nf5Us2Meta?.path ?? "/admin/orders",
    meta: ordersqtK0Nf5Us2Meta || {},
    alias: ordersqtK0Nf5Us2Meta?.alias || [],
    redirect: ordersqtK0Nf5Us2Meta?.redirect,
    component: () => import("/app/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: transactionsxBQPUJh4s4Meta?.name ?? "admin-transactions___en",
    path: transactionsxBQPUJh4s4Meta?.path ?? "/en/admin/transactions",
    meta: transactionsxBQPUJh4s4Meta || {},
    alias: transactionsxBQPUJh4s4Meta?.alias || [],
    redirect: transactionsxBQPUJh4s4Meta?.redirect,
    component: () => import("/app/pages/admin/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsxBQPUJh4s4Meta?.name ?? "admin-transactions___fr",
    path: transactionsxBQPUJh4s4Meta?.path ?? "/admin/transactions",
    meta: transactionsxBQPUJh4s4Meta || {},
    alias: transactionsxBQPUJh4s4Meta?.alias || [],
    redirect: transactionsxBQPUJh4s4Meta?.redirect,
    component: () => import("/app/pages/admin/transactions.vue").then(m => m.default || m)
  },
  {
    name: indexFXjYqPb3WZMeta?.name ?? "customers___en",
    path: indexFXjYqPb3WZMeta?.path ?? "/en/customers",
    meta: indexFXjYqPb3WZMeta || {},
    alias: indexFXjYqPb3WZMeta?.alias || [],
    redirect: indexFXjYqPb3WZMeta?.redirect,
    component: () => import("/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFXjYqPb3WZMeta?.name ?? "customers___fr",
    path: indexFXjYqPb3WZMeta?.path ?? "/customers",
    meta: indexFXjYqPb3WZMeta || {},
    alias: indexFXjYqPb3WZMeta?.alias || [],
    redirect: indexFXjYqPb3WZMeta?.redirect,
    component: () => import("/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name ?? "dashboard___en",
    path: indexhg2nWJrbRRMeta?.path ?? "/en/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    alias: indexhg2nWJrbRRMeta?.alias || [],
    redirect: indexhg2nWJrbRRMeta?.redirect,
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name ?? "dashboard___fr",
    path: indexhg2nWJrbRRMeta?.path ?? "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    alias: indexhg2nWJrbRRMeta?.alias || [],
    redirect: indexhg2nWJrbRRMeta?.redirect,
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937Kbaa5vEkyMeta?.name ?? "invite-id___en",
    path: _91id_937Kbaa5vEkyMeta?.path ?? "/en/invite/:id()",
    meta: _91id_937Kbaa5vEkyMeta || {},
    alias: _91id_937Kbaa5vEkyMeta?.alias || [],
    redirect: _91id_937Kbaa5vEkyMeta?.redirect,
    component: () => import("/app/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_937Kbaa5vEkyMeta?.name ?? "invite-id___fr",
    path: _91id_937Kbaa5vEkyMeta?.path ?? "/invite/:id()",
    meta: _91id_937Kbaa5vEkyMeta || {},
    alias: _91id_937Kbaa5vEkyMeta?.alias || [],
    redirect: _91id_937Kbaa5vEkyMeta?.redirect,
    component: () => import("/app/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___en",
    path: indexgK5VicK7y2Meta?.path ?? "/en/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___fr",
    path: indexgK5VicK7y2Meta?.path ?? "/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout___en",
    path: logoutHaVXWqg8UAMeta?.path ?? "/en/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout___fr",
    path: logoutHaVXWqg8UAMeta?.path ?? "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: filesicbWYSgJyxMeta?.name ?? "orders-id-files___en",
    path: filesicbWYSgJyxMeta?.path ?? "/en/orders/:id()/files",
    meta: filesicbWYSgJyxMeta || {},
    alias: filesicbWYSgJyxMeta?.alias || [],
    redirect: filesicbWYSgJyxMeta?.redirect,
    component: () => import("/app/pages/orders/[id]/files.vue").then(m => m.default || m)
  },
  {
    name: filesicbWYSgJyxMeta?.name ?? "orders-id-files___fr",
    path: filesicbWYSgJyxMeta?.path ?? "/orders/:id()/files",
    meta: filesicbWYSgJyxMeta || {},
    alias: filesicbWYSgJyxMeta?.alias || [],
    redirect: filesicbWYSgJyxMeta?.redirect,
    component: () => import("/app/pages/orders/[id]/files.vue").then(m => m.default || m)
  },
  {
    name: createnzDtnXXm8IMeta?.name ?? "orders-create___en",
    path: createnzDtnXXm8IMeta?.path ?? "/en/orders/create",
    meta: createnzDtnXXm8IMeta || {},
    alias: createnzDtnXXm8IMeta?.alias || [],
    redirect: createnzDtnXXm8IMeta?.redirect,
    component: () => import("/app/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: createnzDtnXXm8IMeta?.name ?? "orders-create___fr",
    path: createnzDtnXXm8IMeta?.path ?? "/orders/create",
    meta: createnzDtnXXm8IMeta || {},
    alias: createnzDtnXXm8IMeta?.alias || [],
    redirect: createnzDtnXXm8IMeta?.redirect,
    component: () => import("/app/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders___en",
    path: indexG1Ehu1sqkUMeta?.path ?? "/en/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders___fr",
    path: indexG1Ehu1sqkUMeta?.path ?? "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vZXVq7IOTKMeta?.name ?? "password-reset-id___en",
    path: _91id_93vZXVq7IOTKMeta?.path ?? "/en/password-reset/:id()",
    meta: _91id_93vZXVq7IOTKMeta || {},
    alias: _91id_93vZXVq7IOTKMeta?.alias || [],
    redirect: _91id_93vZXVq7IOTKMeta?.redirect,
    component: () => import("/app/pages/password-reset/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vZXVq7IOTKMeta?.name ?? "password-reset-id___fr",
    path: _91id_93vZXVq7IOTKMeta?.path ?? "/password-reset/:id()",
    meta: _91id_93vZXVq7IOTKMeta || {},
    alias: _91id_93vZXVq7IOTKMeta?.alias || [],
    redirect: _91id_93vZXVq7IOTKMeta?.redirect,
    component: () => import("/app/pages/password-reset/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___en",
    path: indexYgRHYxGzjsMeta?.path ?? "/en/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___fr",
    path: indexYgRHYxGzjsMeta?.path ?? "/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: index4DIviTw3fSMeta?.name ?? "payments___en",
    path: index4DIviTw3fSMeta?.path ?? "/en/payments",
    meta: index4DIviTw3fSMeta || {},
    alias: index4DIviTw3fSMeta?.alias || [],
    redirect: index4DIviTw3fSMeta?.redirect,
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index4DIviTw3fSMeta?.name ?? "payments___fr",
    path: index4DIviTw3fSMeta?.path ?? "/payments",
    meta: index4DIviTw3fSMeta || {},
    alias: index4DIviTw3fSMeta?.alias || [],
    redirect: index4DIviTw3fSMeta?.redirect,
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938d7mq4Zm2kMeta?.name ?? "register-id___en",
    path: _91id_938d7mq4Zm2kMeta?.path ?? "/en/register/:id()",
    meta: _91id_938d7mq4Zm2kMeta || {},
    alias: _91id_938d7mq4Zm2kMeta?.alias || [],
    redirect: _91id_938d7mq4Zm2kMeta?.redirect,
    component: () => import("/app/pages/register/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_938d7mq4Zm2kMeta?.name ?? "register-id___fr",
    path: _91id_938d7mq4Zm2kMeta?.path ?? "/register/:id()",
    meta: _91id_938d7mq4Zm2kMeta || {},
    alias: _91id_938d7mq4Zm2kMeta?.alias || [],
    redirect: _91id_938d7mq4Zm2kMeta?.redirect,
    component: () => import("/app/pages/register/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhOBcdtdQ4pMeta?.name ?? "register___en",
    path: indexhOBcdtdQ4pMeta?.path ?? "/en/register",
    meta: indexhOBcdtdQ4pMeta || {},
    alias: indexhOBcdtdQ4pMeta?.alias || [],
    redirect: indexhOBcdtdQ4pMeta?.redirect,
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexhOBcdtdQ4pMeta?.name ?? "register___fr",
    path: indexhOBcdtdQ4pMeta?.path ?? "/register",
    meta: indexhOBcdtdQ4pMeta || {},
    alias: indexhOBcdtdQ4pMeta?.alias || [],
    redirect: indexhOBcdtdQ4pMeta?.redirect,
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: accountFbmhd9M064Meta?.name ?? "settings-account___en",
    path: accountFbmhd9M064Meta?.path ?? "/en/settings/account",
    meta: accountFbmhd9M064Meta || {},
    alias: accountFbmhd9M064Meta?.alias || [],
    redirect: accountFbmhd9M064Meta?.redirect,
    component: () => import("/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: accountFbmhd9M064Meta?.name ?? "settings-account___fr",
    path: accountFbmhd9M064Meta?.path ?? "/settings/account",
    meta: accountFbmhd9M064Meta || {},
    alias: accountFbmhd9M064Meta?.alias || [],
    redirect: accountFbmhd9M064Meta?.redirect,
    component: () => import("/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: userCkZPhnbvCdMeta?.name ?? "settings-user___en",
    path: userCkZPhnbvCdMeta?.path ?? "/en/settings/user",
    meta: userCkZPhnbvCdMeta || {},
    alias: userCkZPhnbvCdMeta?.alias || [],
    redirect: userCkZPhnbvCdMeta?.redirect,
    component: () => import("/app/pages/settings/user.vue").then(m => m.default || m)
  },
  {
    name: userCkZPhnbvCdMeta?.name ?? "settings-user___fr",
    path: userCkZPhnbvCdMeta?.path ?? "/settings/user",
    meta: userCkZPhnbvCdMeta || {},
    alias: userCkZPhnbvCdMeta?.alias || [],
    redirect: userCkZPhnbvCdMeta?.redirect,
    component: () => import("/app/pages/settings/user.vue").then(m => m.default || m)
  },
  {
    name: indexzqmHNbk6IKMeta?.name ?? "stocks___en",
    path: indexzqmHNbk6IKMeta?.path ?? "/en/stocks",
    meta: indexzqmHNbk6IKMeta || {},
    alias: indexzqmHNbk6IKMeta?.alias || [],
    redirect: indexzqmHNbk6IKMeta?.redirect,
    component: () => import("/app/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexzqmHNbk6IKMeta?.name ?? "stocks___fr",
    path: indexzqmHNbk6IKMeta?.path ?? "/stocks",
    meta: indexzqmHNbk6IKMeta || {},
    alias: indexzqmHNbk6IKMeta?.alias || [],
    redirect: indexzqmHNbk6IKMeta?.redirect,
    component: () => import("/app/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexH6dMEPckhVMeta?.name ?? "team___en",
    path: indexH6dMEPckhVMeta?.path ?? "/en/team",
    meta: indexH6dMEPckhVMeta || {},
    alias: indexH6dMEPckhVMeta?.alias || [],
    redirect: indexH6dMEPckhVMeta?.redirect,
    component: () => import("/app/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: indexH6dMEPckhVMeta?.name ?? "team___fr",
    path: indexH6dMEPckhVMeta?.path ?? "/team",
    meta: indexH6dMEPckhVMeta || {},
    alias: indexH6dMEPckhVMeta?.alias || [],
    redirect: indexH6dMEPckhVMeta?.redirect,
    component: () => import("/app/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930r1TxNLWFNMeta?.name ?? "team-users-id___en",
    path: _91id_930r1TxNLWFNMeta?.path ?? "/en/team/users/:id()",
    meta: _91id_930r1TxNLWFNMeta || {},
    alias: _91id_930r1TxNLWFNMeta?.alias || [],
    redirect: _91id_930r1TxNLWFNMeta?.redirect,
    component: () => import("/app/pages/team/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930r1TxNLWFNMeta?.name ?? "team-users-id___fr",
    path: _91id_930r1TxNLWFNMeta?.path ?? "/team/users/:id()",
    meta: _91id_930r1TxNLWFNMeta || {},
    alias: _91id_930r1TxNLWFNMeta?.alias || [],
    redirect: _91id_930r1TxNLWFNMeta?.redirect,
    component: () => import("/app/pages/team/users/[id].vue").then(m => m.default || m)
  }
]