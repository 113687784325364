const LOGGING = false;

export default defineNuxtRouteMiddleware(async (to, from) => {
  LOGGING && console.log("--------------------");
  if (to.meta?.auth === false) {
    LOGGING && console.log(`${ from.path } -> ${ to.path } is not protected by auth`);
    return true;
  } else LOGGING && console.log(`[ROUTER] ${ from.path } -> ${ to.path }`);
  const auth = useAuth(); // Utilise ton composable useAuth
  // Attendre la vérification de l'état d'authentification
  await auth.refreshAuthStatus();
  const authStatus = auth.getAuthStatus();

  LOGGING && console.log("[AUTH] Auth status:", authStatus.status);

  if (authStatus.status === "unauthenticated" && to.path !== "/login") {
    // Si l'utilisateur n'est pas authentifié et n'est pas sur la page de connexion,
    // rediriger vers la page de connexion
    return navigateTo("/login");
  }

  if (to.path?.startsWith("/admin") && !authStatus.data?.user?.isAdmin) {
    setPageLayout("default");
    return navigateTo("/");
  } else if (to.path?.startsWith("/admin")) setPageLayout("admin");

  const nuxtApp = useNuxtApp();
  if (!nuxtApp.$account) nuxtApp.provide("account", authStatus.data?.account);
  if (!nuxtApp.$user) nuxtApp.provide("user", {
    ...authStatus.data?.user,
    permissions: authStatus.data?.permissions
  });

  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(["setPath"], to.path);
  _hsq.push(["trackPageView"]);

  _hsq.push(["identify", {
    email: nuxtApp.$user.email,
    id: nuxtApp.$user.id
  }]);

  return true;
});
