let runtimeConfig = null;

const fetch = async (url, opt = {}) => {
	if (!runtimeConfig) runtimeConfig = useRuntimeConfig();
	const { bypassAuth } = opt;
	const token = useCookie("token"); // User token
	if (!token.value && !bypassAuth) return navigateTo("/login"); // If no token, redirect to login
	if (url.includes(":accountId")) {
		const { $account } = useNuxtApp();
		url = url.replace(":accountId", $account.id);
	}
	return new Promise(async (resolve, reject) => {
		const res = await $fetch(url, {
			...opt,
			baseURL: runtimeConfig.public.apiBase, // API base URL
			headers: {
				...opt.headers,
				"Authorization": bypassAuth ? "" : `Bearer ${ token.value }` // Add token to headers
			}
		}).catch((e) => {
			if (!e.response) {
				const toast = useToast();
				toast.add({
					id: "global-error",
					title: "Une erreur globale est survenue",
					description: "Veuillez réessayer plus tard",
					icon: "i-heroicons-x-circle",
					color: "red",
					timeout: 5000
				});
				return reject(e);
			}
			if (e.response?._data?.code === "INVALID_CAPTCHA") {
				const toast = useToast();
				toast.add({
					id: "global-error",
					title: "CAPTCHA invalide",
					description: "Vous ne pouvez pas effectuer cette action",
					icon: "i-heroicons-x-circle",
					color: "red",
					timeout: 10000
				});
				return reject(false);
			} else if (e.response?._data?.code === "UNAUTHORIZED") {
				const toast = useToast();
				toast.add({
					id: "global-error",
					title: "Non autorisé",
					description: "Vous n'êtes pas autorisé à effectuer cette action",
					icon: "i-heroicons-x-circle",
					color: "red",
					timeout: 5000
				});
				return reject(false);
			}
			reject(e.response._data);
		});
		resolve({
			...res
		});
	});
};

const getPage = (array, page, max = 20) => {
	if (page === 1) return array.slice(0, max);
	return array.slice((page - 1) * max, page * max);
};

const base64ToFile = (data, filename) => {
	const arr = data.split(",");
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) u8arr[n] = bstr.charCodeAt(n);
	return new File([u8arr], filename, {
		type: mime
	});
};


const getLength = (array, max) => {
	return Math.ceil(array.length / max);
};

const getCustomerDisplayname = (customer) => {
	let displayname = "";
	displayname += customer.company ? `${ customer.company }` : "";
	if (customer.company && (customer.firstname || customer.lastname)) displayname += " (";
	displayname += customer.firstname ? `${ customer.firstname } ` : "";
	displayname += customer.lastname ? `${ customer.lastname } ` : "";
	if (displayname.endsWith(" ")) displayname = displayname.slice(0, -1);
	if (customer.company && (customer.firstname || customer.lastname)) displayname += ")";
	if (displayname.endsWith(" ")) displayname = displayname.slice(0, -1);
	return displayname;
};


const MONTHS_LONG = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
const MONTHS_SHORT = ["Jan.", "Fév.", "Mar.", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];
const DAYS_LONG = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
const DAYS_SHORT = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];
const DAYS_SHORT_LOWER = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."];

const pad = (n) => (n < 10 ? "0" + n : n);

/**
 * Format DATE
 * @param {Date} date
 * @param {String} format
 * @return {String}
 */
const formatDate = (date, format = "dd/mm/YYYY HH:ii:ss") => {
	if (!(date instanceof Date)) date = new Date(date);

	const replacements = {
		YYYY: date.getFullYear(),
		MMMM: MONTHS_LONG[date.getMonth()],
		MMM: MONTHS_SHORT[date.getMonth()],
		MM: pad(date.getMonth() + 1),
		dddd: DAYS_LONG[date.getDay()],
		DDD: DAYS_SHORT[date.getDay()],
		ddd: DAYS_SHORT_LOWER[date.getDay()],
		dd: pad(date.getDate()),
		HH: pad(date.getHours()),
		hh: pad(date.getHours() % 12 || 12),
		ii: pad(date.getMinutes()),
		ss: pad(date.getSeconds()),
		SSS: date.getMilliseconds().toString().padStart(3, "0"),
	};

	const escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	const literals = [];


	format = format.replace(/'([^']*)'/g, (_, literal) => {
		literals.push(literal);
		return "\0";
	});

	Object.entries(replacements).forEach(([key, value]) => {
		format = format.replace(new RegExp(escapeRegex(key), "g"), value);
	});

	return format.replace(/\0/g, () => literals.shift());
};


const redirectToPayments = () => {
	const toast = useToast();
	toast.add({
		id: "account",
		title: "Vous n'avez pas d'abonnement",
		description: "Vous n'avez pas d'abonnement, vous ne pouvez pas accéder à cette page",
		icon: "i-heroicons-information-circle",
		color: "red",
		timeout: 3000
	});
	navigateTo("/payments");
};

const logout = () => {
	document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	window.location.href = "/login";
};

const toast = {
	success: (title, description) => {
		const toast = useToast();
		toast.add({
			id: "success",
			title,
			description,
			icon: "i-heroicons-check-circle",
			color: "green",
			timeout: 5000
		});
	}
};

async function generateCanva(width, height, id) {
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;

	const poppinsRegular = new FontFace("Poppins", "url(/fonts/Poppins/Poppins-Regular.woff2)", {
		style: "normal",
		weight: "400"
	});
	const poppinsSemibold = new FontFace("Poppins", "url(/fonts/Poppins/Poppins-SemiBold.woff2)", {
		style: "normal",
		weight: "600"
	});

	await Promise.all([poppinsRegular.load(), poppinsSemibold.load()]);

	const ctx = canvas.getContext("2d");
	ctx.roundRect = function (x, y, width, height, radius, degrees) {
		console.log("Rounded rectangle");
		if (!degrees) degrees = 0;
		ctx.save();
		ctx.translate(x + width / 2, y + height / 2);
		ctx.rotate(degrees * Math.PI / 180.0);
		ctx.translate(-x - width / 2, -y - height / 2);
		if (width < 2 * radius) radius = width / 2;
		if (height < 2 * radius) radius = height / 2;
		ctx.beginPath();
		ctx.moveTo(x + radius, y);
		ctx.arcTo(x + width, y, x + width, y + height, radius);
		ctx.arcTo(x + width, y + height, x, y + height, radius);
		ctx.arcTo(x, y + height, x, y, radius);
		ctx.arcTo(x, y, x + width, y, radius);
		ctx.closePath();
		console.log("Rounded rectangle drawn");
	};
	ctx.drawCustomImage = (image, x, y, width, height, opacity = 1, cutted_stroke = false) => {
		console.log("Drawing image");
		if (opacity) ctx.globalAlpha = opacity;
		ctx.drawImage(image, x, y, width, height);
		ctx.strokeStyle = "#000000";
		ctx.setLineDash([]);
		ctx.lineWidth = 1;
		ctx.globalAlpha = 1;
		ctx.strokeRect(x, y, width, height);
		if (cutted_stroke) {
			ctx.strokeStyle = "#FFFFFF";
			ctx.lineWidth = 4;
			ctx.setLineDash([]);
			ctx.roundRect(x + 35.43, y + 35.43, width - 35.43 * 2, height - 35.43 * 2, 11.34 * 4.1667);
			ctx.stroke();
			ctx.strokeStyle = "#39B4A7";
			ctx.lineWidth = 4;
			ctx.setLineDash([31.74]);
			ctx.roundRect(x + 35.43, y + 35.43, width - 35.43 * 2, height - 35.43 * 2, 11.34 * 4.1667);
			ctx.stroke();
			ctx.strokeStyle = "#000000";
			ctx.lineWidth = 1;
			ctx.setLineDash([]);
		}
		console.log("Image drawn");
		ctx.restore();
	};

	return {
		canvas,
		ctx
	};
}

export { generateCanva };

const formatDay = (date) => {
	date = new Date(date);

	// display date like 12 june 2020
	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	// tableau des 12 mois en français
	const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
	return `${ day } ${ months[month] } ${ year }`;
};
export { formatDay };

export {
	fetch,
	getPage,
	getLength,
	formatDate,
	base64ToFile,
	getCustomerDisplayname,
	redirectToPayments,
	logout,
	toast
};
